import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

import { Mails } from 'src/app/interfaces/mails';
import { News } from 'src/app/interfaces/news';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  public news:  News  = <News>{};
  public mails: Mails = <Mails>{};
  public innerWidth: any;

  emailContact: string = 'contato@tecnopharmagroup.com.br'

  newsForm:   FormGroup;
  mailsForm:  FormGroup;
  mostraDepo: boolean = false;
  mostraBtnBottom     = false;  
  disabledSubmitButton: boolean = true;  
  // emailNews: string;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (document.documentElement.scrollTop <= 400) {
      this.mostraBtnBottom = false;
    } else {
      this.mostraBtnBottom = true;
    }
  }

  @HostListener('input') oninput() {
  
    if (this.mailsForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  constructor(public  fb: FormBuilder,
              private authService: AuthService,
              private toastr: ToastService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.newsForm = this.fb.group({
      emailNF: ['', [Validators.required, Validators.email]]
    });

    this.mailsForm = this.fb.group({
      nomeMF:     ['', Validators.required, Validators.minLength(3)],
      emailMF:    ['', [Validators.required, Validators.email]],
      empresaMF:  ['', Validators.required],
      telefoneMF: ['', Validators.required],
      mensagemMF: ['', Validators.required]
    });

    this.innerWidth = window.innerWidth;        
    // Mostra/Esconde Botão de fim de página para Scroll
    this.authService.mostraBtnBottomEmitter.subscribe(
      mostrar => (this.mostraBtnBottom = mostrar)
    ); 
  }

  get nomeMF() { return this.mailsForm.get('nomeMF'); }

  get emailMF() { return this.mailsForm.get('emailMF'); }  

  get empresaMF() { return this.mailsForm.get('empresaMF'); }  

  get telefoneMF() { return this.mailsForm.get('emailMF'); }  

  get mensagemMF() { return this.mailsForm.get('emailMF'); }  
  
  get emailNF() { return this.newsForm.get('emailNF'); }

 /* @HostListener('window:resize')
  onWindowResize() {
    if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
      this.carouselDisplayMode = 'single';
    } else {
      this.carouselDisplayMode = 'multiple';
    }
  } */  

  scrollBME(Status: boolean):void {
    if (Status) {
      this.authService.mostraBtnBottomEmitter.emit(true);
    } else {
      this.authService.mostraBtnBottomEmitter.emit(false);
    }      
  }

  onSubmit() {
    // const options = { positionClass: 'md-toast-top-center'};
    this.news = this.newsForm.value;
    this.authService.cadNews(this.news).subscribe(response => {
        this.toastr.success('Cadastro realizado com sucesso, em breve você receberá nossas newslatter.', 'ATENÇÃO');
        this.newsForm.reset();
      },
      err => this.toastr.error(err.error, 'ATENÇÃO')
    );
  }

  onSubmitMails() {
    // const options = { positionClass: 'md-toast-top-center'};
    if(!this.mailsForm.valid) {
      this.toastr.error('Preencha todos os campos do formulário!');
    } else {
      this.mails = this.mailsForm.value;
      this.authService.cadMails(this.mails).subscribe(response => {
          this.toastr.success('Email enviado com sucesso, em breve faremos contato.', 'ATENÇÃO');
          this.mailsForm.reset();
        },
        err => this.toastr.error(err.error, 'ATENÇÃO')
      );
    }
  }

  mostraVP():void {
    this.authService.menuColorEmitter.emit(true);    
    this.authService.menuMainEmitter.emit(false);
    this.authService.menuVPEmitter.emit(true);
    this.router.navigate(['/vendasPremiada']);
  }

  pharmapedDist(): void{
    this.authService.menuMainEmitter.emit(false);
    this.authService.menuSharedEmitter.emit(true);
    this.authService.menuColorEmitter.emit(true);
    this.router.navigate(['/pharmapedDist']);
  }

  pharmapedFarma(): void{
    this.authService.menuMainEmitter.emit(false);
    this.authService.menuSharedEmitter.emit(true);
    this.authService.menuColorEmitter.emit(true);
    this.router.navigate(['/pharmapedFarma']);
  }

}

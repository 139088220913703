<div container id="intro-section" class="view">
  <video *ngIf="innerWidth > 414" id="vid" class="video-fluid" poster="https://clubb2b.com.br/images/main/background.jpg"
           allowfullscreen playsinline oncanplay="this.play()" onloadedmetadata="this.muted=true" loop
           style="min-width: 100%; min-height: 100%;">
    <source src="https://tecnopharmagroup.com.br/videos/pharma.mp4" type="video/mp4">
  </video>

  <div class="mask rgba-gradient d-md-flex justify-content-center align-items-center overflow-auto">
    <div class="container px-md-3 px-sm-0">
      <div class="row wow fadeIn">
        <div class="col-md-12 mb-4 white-text text-center wow fadeIn">
          <h3 *ngIf="innerWidth > 414" class="display-3 font-weight-bold amber-text mb-0 mt-5 pt-md-5 pt-5 sombra-title">
            Farma Business
          </h3>
          <h1 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold amber-text mb-0 mt-5 pt-md-5 pt-5 sombra-title">
            <a class="font-weight-bold green-text sombra-title">Tecno</a>Pharma
            <a class="font-weight-bold indigo-text sombra-title">Group</a>
          </h1>
          <hr class="hr-light my-4 w-100">
          <h4 class="subtext-header mt-2 mb-4 text-justify sombra-title">
            Somos uma empresa comprometida com a inovação tecnológica, desenvolvemos soluções que
            aproximam, humanizam e integram empresas à empresas 
            (<a class="font-weight-bold text-color-a sombra-title">B</a>
            <a class="font-weight-bold text-color-b sombra-title">2</a>
            <a class="font-weight-bold text-color-c sombra-title">B</a>), e empresas à pessoas 
            (<a class="font-weight-bold text-color-a sombra-title">B</a>
            <a class="font-weight-bold text-color-b sombra-title">2</a>
            <a class="font-weight-bold text-color-c sombra-title">C</a>), criando e gerando através de nossas
            tecnologias novas oportunidades de negócios para o setor farmacêutico.
            Nosso compromisso é maximizar e ampliar a geração de oportunidades de negócios 
            <a class="font-weight-bold text-color-a sombra-title">B</a>
            <a class="font-weight-bold text-color-b sombra-title">2</a>
            <a class="font-weight-bold text-color-c sombra-title">B</a> e 
            <a class="font-weight-bold text-color-a sombra-title">B</a>
            <a class="font-weight-bold text-color-b sombra-title">2</a>
            <a class="font-weight-bold text-color-c sombra-title">C</a> para toda 
            a cadeia de negócios do setor farmacêutico.
          </h4>
          <h4 class="text-center sombra-title">Nós levamos a gestão Pharma muito a sério.</h4>
          <ul class="list-unstyled">
            <a (click)="scrollBME(true)" mdbPageScroll href="#informe" mdbBtn color="orange" rounded="true"
              outline="true" mdbWavesEffect [pageScrollDuration]="750">
              <i class="fa fa-arrow-down"></i>
            </a>
        </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="informe" class="view intro" 
     style="background-image: url(https://clubb2b.com.br/images/main/informativo.jpg); background-attachment: fixed; height: 300px; 
            background-position: center; background-repeat: no-repeat; background-size: cover;">
  <div class="full-bg-img mask rgba-white-light">
    <div class="container pt-3 pb-5">
      <div class="d-flex align-items-center d-flex justify-content-center" style="height: 300px">
        <div class="row mt-3">
          <div class="col-md-12 wow fadeIn mb-3">
            <form [formGroup]="newsForm"  (ngSubmit)="onSubmit()" class="text-center">
              <h2 class="font-weight-bold h1 py-3 pb-2">
                <strong class="teal-text sombra-title">Informativo</strong>
              </h2>
              <p>Cadastre-se em nossa lista de emails, e fique atualizado com nossas novidades.</p>
              <div class="row mt-3">
                <div class="col-md-8">
                  <input type="email" ([ngModel])="emailNF" id="email" class="form-control form-control-sm mb-4"
                      formControlName="emailNF" placeholder="Increva-se, informe aqui seu E-mail">
                </div>
                <div class="col-md-4">
                  <button type="submit" mdbBtn color="danger" block="true" size="sm" mdbWavesEffect
                    [disabled]="!newsForm.valid">Inscreva-se</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Quem Somos -->
<div id="about" class="bg text-center py-5 pb-5">
  <div class="container px-md-3 px-sm-0">
    <section class="text-center">
      <h2 class="font-weight-bold h1 py-3 pb-2">
        <strong class="teal-text sombra">Quem Somos</strong>
      </h2>
      <p class="grey-text text-justify py-2">
        Somos uma Startup voltada para inovação tecnológica, e temos como meta principal
        levar sempre aos nossos clientes tecnológia de ponta e serviços de qualidade.
        Apresentamos aqui os principais conceitos que moldam e dão norte a nossa Startup.
      </p>  
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <mdb-card cascade="true">
            <div class="view view-cascade gradient-card-header aqua-gradient">
              <h2 class="card-header-title">MISSÃO</h2>
              <p>Nossa Missão</p>
            </div>
            <mdb-card-body cascade="true" class="text-justify">
              <mdb-card-text>
                Prover Soluções de Tecnologia da Informação mais adequadas para o alcance dos objetivos 
                comerciais dos nossos clientes, ser referência de atuação do setor tecnológico em ambiente 
                corporativo. Produzir softwares comerciais inovadores e de qualidade,
                que atendam as regras de negócios do setor Farmacêutico.
                <br><br><br>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>

        <div class="col-lg-4 col-md-6">
          <mdb-card cascade="true">
            <div class="view view-cascade gradient-card-header aqua-gradient">
              <h2 class="card-header-title">VISÃO</h2>
              <p>Nossa Visão</p>
            </div>

            <mdb-card-body cascade="true" class="text-justify">
              <mdb-card-text>
                Ser referência em eficiência, inovação e qualidade de serviços no ambiente corporativo, 
                através da excelência de Soluções de Tecnologia da Informação. Assegurar a sustentabilidade 
                do negócio com inovação, responsabilidade social e ambiental, bem como promover o bem-estar 
                das pessoas envolvidas nos processos corporativos.
                <br><br>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>

        <div class="col-lg-4 col-md-6">
          <mdb-card cascade="true">
            <div class="view view-cascade gradient-card-header aqua-gradient">
              <h2 class="card-header-title">VALORES</h2>
              <p>Nossos Valores</p>
            </div>
            <mdb-card-body cascade="true" class="text-justify">
              <mdb-card-text>
                <i class="fa fa-check"></i>&nbsp;Eficiência
                <div><i class="fa fa-check"></i>&nbsp;Proatividade</div>
                <div><i class="fa fa-check"></i>&nbsp;Valorização humana</div>
                <div><i class="fa fa-check"></i>&nbsp;Preocupação com a satisfação do cliente</div>
                <div><i class="fa fa-check"></i>&nbsp;Postura colaborativa</div>
                <div><i class="fa fa-check"></i>&nbsp;Transparência</div>
                <div><i class="fa fa-check"></i>&nbsp;Comprometimento</div>
                <div><i class="fa fa-check"></i>&nbsp;Seriedade</div>
                <br>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </section>
    <button (click)="scrollBME(false)" [hidden]="!mostraBtnBottom"    
      class="btn btn-floating btn-large deep-orange waves-light" mdbPageScroll href="#intro-section" mdbWavesEffect
      style="right:35px; bottom:35px;" [pageScrollDuration]="750"
      mdbTooltip="Vai para o Inicio da Página." placement="left">
      <i class="fa fa-arrow-up"></i>
    </button>
  </div>
</div>

<div id="area" class="container pt-5 pb-3">
  <section class="text-center">
    <h2 class="font-weight-bold h1 py-3 teal-text sombra">
      <strong>Área de Atuação</strong>
    </h2>
    <p class="grey-text pb-5 text-justify">
      Possuimos uma ampla área de atuação no setor farmacêutico, abrangendo toda cadeia de processos do setor, 
      desde à industria, passando pelos distribuidores, chegando ao ponto final de vendas, os PDV´s.
    </p>
    <div class="d-flex row">        
      <div class="col-md-12 mb-4">          
        <mdb-card class="card-image img-fluid mb-3 mx-2" 
                  style="background-image: url(https://clubb2b.com.br/images/main/distribuidora.png);">
          <div class="mask rgba-black-light text-white text-center d-flex align-items-center py-5 px-4 rounded">
            <div>
              <h6 class="amber-text">
                <mdb-icon fas icon="truck-moving"></mdb-icon>
                <strong> Distribuidoras</strong>
              </h6>
              <h3 class="py-3 font-weight-bold">
                <strong>Distribuidoras de Medicamentos, Perfumarias e Hospitalares</strong>
              </h3>
              <p class="pb-3 text-justify">
                Conectamos sua distribuidora com toda cadeia de vendas do setor farmacêutico, desde à
                industria, à seus clientes de farmácias, drogarias, e lojas de cosméticos. Possuimos 
                as ferramentas certas para auxilia-lo nas vendas de seus produtos aos seus clientes.
              </p>
              <a mdbBtn color="unique" size="md" rounded="true" class="waves-light" mdbWavesEffect>
                <mdb-icon fas icon="info-circle" class="amber-text"></mdb-icon> Saiba mais...
              </a>
            </div>
          </div>
        </mdb-card>
      </div>
      <!-- <div class="col-md-6 mb-4">
        <mdb-card class="card-image img-fluid mb-3 mx-2" 
                  style="background-image: url(https://clubb2b.com.br/images/main/pdv.jpg);">
          <div class="mask rgba-black-light text-white text-center d-flex align-items-center py-5 px-4 rounded">
            <div>
              <h6 class="amber-text">
                <mdb-icon fas icon="laptop-medical"></mdb-icon>
                <strong> PDV´s</strong>
              </h6>
              <h3 class="py-3 font-weight-bold">
                <strong>Farmácias e Drogarias, e Lojas de Perfumarias</strong>
              </h3>
              <p class="pb-3 text-justify">
                Com tecnologias inovadoras, integramos seu PDV à todo o processo de compra e vendas.
                Disponibilizamos soluções que facilitam e ampliam seu horizonte de vendas, aproximando o consumidor
                final de sua farmácia ou loja de perfumaria.
              </p>
              <a mdbBtn color="unique" size="md" rounded="true" class="waves-light" mdbWavesEffect>
                <mdb-icon fas icon="info-circle" class="amber-text"></mdb-icon> Saiba mais...
              </a>
            </div>
          </div>
        </mdb-card>
      </div> -->
      <div class="col-md-12 mb-4">
        <mdb-card class="card-image img-fluid mb-3 mx-2" 
                  style="background-image: url(https://tecnopharmagroup.com.br/imgs/industria.jpg);">
          <div class="mask rgba-black-strong text-white text-center d-flex align-items-center py-5 px-4 rounded">
            <div>
              <h6 class="amber-text">
                <mdb-icon fas icon="industry"></mdb-icon>
                <strong> Indústria</strong>
              </h6>
              <h3 class="py-3 font-weight-bold">
                <strong>Indústria Farmacêutica e Cosméticos</strong>
              </h3>
              <p class="pb-3">Possuímos soluções para toda a indústria farmacêutica
                e indústria de cosméticos. Desenvolvemos soluções específicas que se adequam
                às necessidades de nossos clientes, agregando valor e gerando negócios para
                toda cadeia comercial do setor.
              </p>
              <a mdbBtn color="unique" size="md" rounded="true" class="waves-light" mdbWavesEffect>
                <mdb-icon fas icon="info-circle" class="amber-text"></mdb-icon> Saiba mais...
              </a>
            </div>
          </div>
        </mdb-card>
      </div>
    </div>
  </section>

  <!-- VENDAS PREMIADA -->
  <div id="vendaspremiada" class="container text-center pt-4">
    <section class="text-center pb-3">
      <h2 class="font-weight-bold h1 py-5">
        <strong>VENDA PREMIADA</strong>
      </h2>
      <p class="grey-text pb-5 text-justify">
        Se você deseja aumentar as vendas de seu negócio, o 
        <a class="font-weight-bold red-text">VENDA PREMIADA</a> 
        é a ferramenta ideal para que você possa organizar premiações 
        que incentivarão seus parceiros a vender mais.
        Com o <a class="font-weight-bold red-text">VENDA PREMIADA</a>, 
        você pode criar campanhas de vendas do jeito que você quiser, 
        e com a flexibilidade e a rapidez que o seu negócio precisa. 
      </p>
      <div class="row">
        <div class="col-md-12 mb-4">
          <mdb-card class="card-image mb-3 mx-2" style="background-image: url(https://clubb2b.com.br/images/main/vendas-premiadas.jpg)">
            <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
              <div>
                <h5 class="purple-text">
                  <mdb-icon fas icon="money"></mdb-icon>
                  <strong> PREMIE SEUS PARCEIROS</strong>
                </h5>
                <h3 class="py-3 font-weight-bold">
                  <strong>VENDAS QUE DÃO PRÊMIOS</strong>
                </h3>
                <p class="pb-3 text-justify">
                  Categorize as vendas de seus produtos por faixas de Metas de Vendas, 
                  acompanhe o progresso das vendas, informe em tempo real para seus 
                  parceiros de vendas o quanto ele realizou, e o quanto falta realizar
                  para que ele alcance a faixa de Metas de Vendas desejada e obtenha a premiação.
                  Determine qual será o objeto da premiação, se o prêmio será por valor, 
                  produto ou serviço.
                  Essas e muitas outras funções você irá encontrar em nossa ferramenta 
                  automatizada de premiação.
                </p>
                <a mdbBtn color="unique" size="md" rounded="true" class="waves-light" mdbWavesEffect (click)="mostraVP()">
                  <mdb-icon fas icon="info-circle" class="left amber-text"></mdb-icon> 
                  Saiba mais... 
                </a>
              </div>
            </div>
          </mdb-card>
        </div>
      </div>
    </section>
  </div>

  <!-- PHARMAPED -->
  <div id="pharmaped" class="container text-center pt-4">
    <section class="text-center pb-3">
      <h2 class="font-weight-bold h1 py-5">
        <strong>Ecommerce para o Setor Farma</strong>
      </h2>
      <p class="grey-text pb-5 text-justify">
        Aumente as vendas de seu negócio, o 
        <a class="font-weight-bold green-text sombra-title">B</a>
        <a class="font-weight-bold amber-text sombra-title ml-1 mr-1" style="font-size:70% !important;">2</a>
        <a class="font-weight-bold indigo-text sombra-title">B</a>
        <a class="font-weight-bold white-text sombra-title"> Connect</a> 
        é a ferramenta de Ecommerce da 
        <a class="font-weight-bold green-text sombra-title">Tecno</a>
        <a class="font-weight-bold amber-text sombra-title">Pharma</a>
        <a class="font-weight-bold indigo-text sombra-title">Group</a> 
        voltada para o setor Farma. Criado para gerir todo o processo 
        de vendas online entre <strong class="black-text">Distribuidores, OL´s e PDVs</strong> 
        (<a class="font-weight-bold green-text sombra-title">B</a>
        <a class="font-weight-bold amber-text sombra-title">2</a>
        <a class="font-weight-bold indigo-text sombra-title">B</a>), 
        e entre <strong class="black-text">PDVs e seus Clientes</strong> 
        (<a class="font-weight-bold green-text sombra-title">B</a>
        <a class="font-weight-bold amber-text sombra-title">2</a>
        <a class="font-weight-bold indigo-text sombra-title">C</a>). 
      </p>
      <div class="row">
        <div class="col-md-12 mb-4">
          <mdb-card class="card-image mb-3 mx-2" style="background-image: url(https://clubb2b.com.br/images/main/ecommerce_ped.jpg)">
            <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
              <div>
                <h5 class="purple-text">
                  <mdb-icon fab icon="opencart" class="amber-text mr-1 sombra-title"></mdb-icon>                   
                  <a class="font-weight-bold green-text sombra-title">B</a>
                  <a class="font-weight-bold amber-text sombra-title ml-1 mr-1" style="font-size:70% !important;">2</a>
                  <a class="font-weight-bold indigo-text sombra-title">B</a>
                  <a class="font-weight-bold white-text sombra-title"> Connect</a> 
                </h5>
                <h3 class="py-3 font-weight-bold">
                  <strong>SUA EMPRESA ONLINE 24x7</strong>
                </h3>
                <p class="pb-3 text-justify">
                  Com o 
                  <a class="font-weight-bold green-text sombra-title">B</a>
                  <a class="font-weight-bold amber-text sombra-title ml-1 mr-1" style="font-size:70% !important;">2</a>
                  <a class="font-weight-bold indigo-text sombra-title">B</a>
                  <a class="font-weight-bold white-text sombra-title"> Connect</a> 
                  sua empresa estará online 24x7, alcançando seus clientes a 
                  qualquer hora e maximizando as vendas de sua empresa.         
                  Crie campanhas de vendas dos seus produtos disponibilizando 
                  em tempo real as mesmas para seus clientes, defina o periodo 
                  das campanhas com data de início e termino, realize trocas e 
                  devoluções, acompanhe as metas de acordos comercial de vendas, 
                  e muito mais!
                  O <a class="font-weight-bold green-text sombra-title">B</a>
                  <a class="font-weight-bold amber-text sombra-title ml-1 mr-1" style="font-size:70% !important;">2</a>
                  <a class="font-weight-bold indigo-text sombra-title">B</a>
                  <a class="font-weight-bold white-text sombra-title"> Connect</a> 
                  engloba um conjunto de ferramentas que impulsiona e agiliza 
                  todo o processo de vendas 
                  <a class="font-weight-bold green-text sombra-title">B</a>
                  <a class="font-weight-bold amber-text sombra-title">2</a>
                  <a class="font-weight-bold indigo-text sombra-title">B</a> e 
                  <a class="font-weight-bold green-text sombra-title">B</a>
                  <a class="font-weight-bold amber-text sombra-title">2</a>
                  <a class="font-weight-bold indigo-text sombra-title">C</a> do setor Farma. 
                </p>
                <p class="pb-3 text-center">
                  Exclusivo para Farmácias, Distribuidoras de Medicamentos/Perfumarias/Hospitalar e Representantes da Industria - OL.
                </p>
                <a mdbBtn color="unique" size="md" rounded="true" class="waves-light" 
                  mdbWavesEffect (click)="pharmapedFarma()">
                  <mdb-icon fas icon="shopping-cart" class="left amber-text"></mdb-icon> 
                  Adquirir - B2BConnect Farmácias
                </a>
                <a mdbBtn color="unique" size="md" rounded="true" class="waves-light" 
                  mdbWavesEffect (click)="pharmapedDist()">
                  <mdb-icon fas icon="shopping-cart" class="left amber-text"></mdb-icon> 
                  Adquirir - B2BConnect Distirbuidoras 
                </a>
              </div>
            </div>
          </mdb-card>
        </div>
      </div>
    </section>
  </div>

  <!-- PHARMABOT -->
  <div id="pharmabot" class="container text-center pt-4">
    <section class="text-center pb-3">
      <h2 class="font-weight-bold h1 py-5">
        <strong>Venda Automatizada pelo WhatsApp</strong>
      </h2>
      <p class="grey-text pb-5 text-justify">
        Atenda seus clientes sem precisar de 
        <strong class="black-text">Intervenção Humana</strong>, o 
        <a class="font-weight-bold green-text sombra-title">PHA</a>
        <a class="font-weight-bold amber-text sombra-title">RMA</a>      
        <a class="font-weight-bold indigo-text sombra-title">BOT</a>
        é sua ferramenta de ecommerce automatizada para WhatsApp da 
        <a class="font-weight-bold green-text sombra-title">Tecno</a>
        <a class="font-weight-bold amber-text sombra-title">Pharma</a>
        <a class="font-weight-bold indigo-text sombra-title">Group</a>. 
        Ela está revolucionando as vendas do setor Farma 
        <a class="font-weight-bold green-text sombra-title">B</a>
        <a class="font-weight-bold amber-text sombra-title">2</a>
        <a class="font-weight-bold indigo-text sombra-title">C</a>. 
        Criada para gerir todo o processo de vendas pelo WhatsApp, sem a necessidade de  
        alocar <strong class="black-text">Funcionários</strong> 
        para administrar o atendimento dos seus clientes.  
        Ela agiliza todo o processo de vendas entre sua 
        <strong class="black-text">Loja e seus Clientes</strong> 
        gerando mais negócios 
        <a class="font-weight-bold green-text sombra-title">B</a>
        <a class="font-weight-bold amber-text sombra-title">2</a>
        <a class="font-weight-bold indigo-text sombra-title">C</a>. 
      </p>
      <div class="row">
        <div class="col-md-12 mb-4">
          <mdb-card class="card-image mb-3 mx-2" style="background-image: url(https://clubb2b.com.br/images/main/ecommerce_ped.jpg)">
            <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
              <div>
                <h5 class="purple-text">
                  <mdb-icon fab icon="whatsapp" class="green-text mr-1 sombra-title"></mdb-icon>
                  <a class="font-weight-bold green-text sombra-title">PHA</a>
                  <a class="font-weight-bold amber-text sombra-title">RMA</a>                
                  <a class="font-weight-bold indigo-text sombra-title">BOT</a> 
                </h5>
                <h3 class="py-3 font-weight-bold">
                  <strong>
                    VENDAS AUTOMATIZADA POR WHATSAPP
                  </strong>
                </h3>
                <p class="pb-3 text-justify">
                  Com o 
                  <a class="font-weight-bold green-text sombra-title">PHA</a>
                  <a class="font-weight-bold amber-text sombra-title">RMA</a>                
                  <a class="font-weight-bold indigo-text sombra-title">BOT</a> 
                  sua Loja venderá pelo WhatsApp 24x7, maximizando as vendas de sua empresa.         
                  Crie campanhas de vendas dos seus produtos disponibilizando em tempo real 
                  no WhatsApp para seus clientes, defina o periodo das campanhas com data de 
                  início e termino, realize diversas tarefas de fidelização dos seus clientes, 
                  e muito mais!
                  O <a class="font-weight-bold green-text sombra-title">PHA</a>
                  <a class="font-weight-bold amber-text sombra-title">RMA</a>                
                  <a class="font-weight-bold indigo-text sombra-title">BOT</a> 
                  engloba um conjunto de ferramentas que impulsiona e agiliza todo 
                  o processo de vendas por WhatsApp de sua Loja. É muito mais que 
                  uma inovação na geração de negócios 
                  <a class="font-weight-bold green-text sombra-title">B</a>
                  <a class="font-weight-bold amber-text sombra-title">2</a>
                  <a class="font-weight-bold indigo-text sombra-title">C</a> do setor Farma.
                </p>
                <a mdbBtn color="unique" size="md" rounded="true" class="waves-light" mdbWavesEffect (click)="pharmapedFarma()">
                  <mdb-icon fas icon="shopping-cart" class="left amber-text"></mdb-icon> 
                  Adquirir
                </a>
              </div>
            </div>
          </mdb-card>
        </div>
      </div>
    </section>
  </div>

  <!-- PARCERIA -->
  <div id="pc" class="container text-center pt-4">
    <section class="text-center pb-3">
        <h2 class="font-weight-bold h1 py-5">
          <strong>SEJA UM PARCEIRO</strong>
        </h2>
        <p class="grey-text pb-5 text-justify">
          Junte-se a nós e seja um <a class="font-weight-bold red-text">Parceiro</a> 
          comercializando nossos produtos. Tenha uma renda extra e lucre com nossos 
          produtos, o nosso portifólio tem uma ampla lista de soluções para serem 
          comercializadas a diversos seguimentos. Venda quando e de onde quiser, 
          você faz sua própria agenda de vendas. 
        </p>
        <div class="row">
          <div class="col-md-12 mb-4">
            <mdb-card class="card-image mb-3 mx-2" style="background-image: url(https://clubb2b.com.br/images/main/team.jpg)">
              <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
                <div>
                  <h3 class="deep-orange-text">
                    <mdb-icon fas icon="money" class="amber-text"></mdb-icon>
                    <strong> RENDA EXTRA</strong>
                  </h3>
                  <h5 class="py-3 font-weight-bold">
                    <strong>AUMENTE SEUS RENDIMENTOS</strong>
                  </h5>
                  <p class="pb-3 text-justify">
                    Nossos produtos possuem excelente qualidade e aceitação, 
                    com preço acessível a qualquer empresa, o que aumenta suas 
                    chances de ter um rentabilidade considerável nas vendas que 
                    realizar. A <a class="font-weight-bold red-text">parceria 
                    funciona sem vinculo empregatício</a>, venda por indicação, 
                    o que significa que você mesmo faz seu horário de trabalho, 
                    sem patrão. Você indica nossos produtos, fornece seu código 
                    que geramos para identificar suas vendas e após a conclusão
                    da venda, o dinheiro cai automaticamente em uma conta indicada 
                    por você em seu cadastro de pareceiro. O dinheiro da comissão 
                    de sua venda, cai em sua conta minutos após a confirmação do 
                    pagamento do cliente em nossos sistemas. 
                  </p>
                  <p class="pb-3 text-center">
                    <a class="font-weight-bold amber-text">
                      ISSO MESMO, VENDEU, GANHA NA HORA!
                    </a>
                  </p>
                  <a mdbBtn color="unique" size="md" rounded="true" class="waves-light" 
                    mdbWavesEffect>
                    <mdb-icon fas icon="hand-holding-usd" class="left amber-text"></mdb-icon> 
                    Quero ser Parceiro
                  </a>
                </div>
              </div>
            </mdb-card>
          </div>
        </div>
    </section>
  </div>

  <section class="contact-section mt-5 my-5">
    <div id="contato" class="container pt-5">
      <h2 class="font-weight-bold h1 py-3 text-center teal-text sombra">
        <strong>Nós queremos te ouvir</strong>
      </h2>
      <p class="grey-text pb-3 text-center">
        Sua opinião, sugestão ou crítica é muito importante para nós. 
        Ficaremos felizes em poder saber o que você pensa ou precisa. 
        Ouvir nossos clientes impulsiona nossa empresa para melhorar, 
        criar e trazer novas tecnologias que possam contribuir com a 
        geração de novos negócios para sua empresa.
      </p>
      <div class="card z-depth-1">
        <div class="row">
          <div class="col-lg-8 pr-0">
            <form [formGroup]="mailsForm" (ngSubmit)="onSubmitMails()" class="text-center">
              <div class="card-body form white-text full-bg-img bg-c mask rgba-white-light pr-1">
                <h3 class="mt-4">
                  <mdb-icon fas icon="envelope" class="pr-2 amber-text"></mdb-icon>Contate-nos
                </h3>
                <p class="font-weight-bold mt-2 mb-3">Campos marcados com (*) são obrigatórios</p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input mdbInput mdbValidate type="text" formControlName="nomeMF" 
                          id="formNome" class="form-control amber-text">
                      <label for="formNome" class="white-text">(*) Seu nome</label>
                      <mdb-error   *ngIf="nomeMF.invalid && (nomeMF.dirty || nomeMF.touched)">
                        Nome é obrigatório
                      </mdb-error>
                      <mdb-success *ngIf="nomeMF.valid && (nomeMF.dirty || nomeMF.touched)">
                        Nome Ok
                      </mdb-success>                    
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input mdbInput mdbValidate type="email" id="emailMF" formControlName="emailMF" 
                        class="form-control amber-text">
                      <label for="emailMF"  class="white-text">(*) Seu email</label>
                      <mdb-error   *ngIf="emailMF.invalid && (emailMF.dirty || emailMF.touched)">
                        Email é obrigatório
                      </mdb-error>
                      <mdb-success *ngIf="emailMF.valid   && (emailMF.dirty || emailMF.touched)">
                        Email Ok
                      </mdb-success>                    
                    </div>
                  </div>
                </div>
                <div class="row white-text">
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input mdbInput type="text" id="telefone" formControlName="telefoneMF" 
                        class="form-control amber-text">
                      <label for="telefone" class="white-text">(*) Seu telefone</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input mdbInput type="text" id="empresa" formControlName="empresaMF" 
                        class="form-control amber-text">
                      <label for="empresa" class="white-text">(*) Sua empresa</label>
                    </div>
                  </div>
                </div>
                <div class="row white-text">
                  <div class="col-md-12">
                    <div class="md-form mb-0">
                      <textarea mdbInput type="text" ([ngModel])="mails.mensagem" id="mensagem" 
                        formControlName="mensagemMF" class="form-control amber-text md-textarea" 
                        rows="3">
                      </textarea>
                      <label for="mensagem" class="white-text">(*) Sua mensagem</label>
                      <button mdbBtn type="button" color="unique" [disabled]="!mailsForm.valid" 
                        (click)="onSubmitMails()" mdbWavesEffect>
                        <i class="fas fa-paper-plane amber-text"></i>
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-4 pl-0">
            <div class="card-body bg-v  full-bg-img contact text-center h-100 white-text">
              <h3 class="my-4 pb-2">Informações de Contato</h3>
              <ul class="text-lg-left list-unstyled ml-2 black-text">
                <li>
                  <p><i class="fa fa-map-marker amber-text pr-2 sombra"></i>
                    Espirito Santo, Brasil
                  </p>
                </li>
                <li>
                  <!---p><i class="fa fa-phone amber-text pr-2"></i>+55 27 3344 5688</p-->
                </li>
                <li>
                  <p><i class="fa fa-envelope amber-text pr-2 sombra"></i>
                    {{ emailContact }}
                  </p>
                </li>
              </ul>
              <links>
                <hr class="hr-light my-4">
                <ul class="list-unstyled list-inline">
                  <li class="list-inline-item">
                    <a mdbBtn color="indigo" floating="true" size="sm" 
                      class="rgba-white-slight mx-1" mdbWavesEffect
                      mdbTooltip="Visite nossa Página no LinkedIn." placement="bottom">
                      <mdb-icon fab icon="linkedin"></mdb-icon>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a mdbBtn floating="true" size="sm" 
                      class="young-passion-gradient color-block rgba-white-slight mx-1" 
                      mdbWavesEffect
                      mdbTooltip="Visite nossa Página no Instagram." placement="bottom">
                      <mdb-icon fab icon="instagram"></mdb-icon>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a mdbBtn color="dark" floating="true" size="sm" 
                      class="rgba-white-slight mx-1" mdbWavesEffect
                      mdbTooltip="Visite nossa Página no TikTok." placement="bottom">
                      <i class="fa-brands fa-tiktok"></i>
                    </a>
                  </li>                                                      
                  <li class="list-inline-item">
                    <a mdbBtn color="blue" floating="true" size="sm" 
                      class="rgba-white-slight mx-1" mdbWavesEffect
                      mdbTooltip="Visite nossa Página no Facebook." placement="bottom">
                      <mdb-icon fab icon="facebook"></mdb-icon>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a mdbBtn color="cyan" floating="true" size="sm" 
                      class="rgba-white-slight mx-1" mdbWavesEffect
                      mdbTooltip="Visite nossa Página no X (Twitter)." placement="bottom">
                      <mdb-icon fab icon="twitter"></mdb-icon>
                    </a>
                  </li>
                </ul>      
              </links>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
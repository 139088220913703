<router-outlet></router-outlet>
<script>
  $("[title]").tooltip({
    placement: 'top',
    delay: { "show": 500, "hide": 100 },
    offset: '0 10',
    template: '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner bg-black rounded-pill shadow"></div></div>'
  });
</script>

<!-- body class="cyan-skin">

  <app-barra-navegacao></app-barra-navegacao>
  <router-outlet></router-outlet>
  <app-footer-main></app-footer-main>

  <script>
    $("[title]").tooltip({
      placement: 'bottom',
      delay: { "show": 500, "hide": 100 },
      offset: '0 10',
      template: '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner bg-black rounded-pill shadow"></div></div>'
    });
  </script>
</body -->
<!-- footer *ngIf="menuFooter" class="page-footer font-small cyan-skin darken-4 pt-4">
  <div class="container text-center text-md-left">
    <div class="row text-center text-md-left mt-3 pb-3">
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">TecnoPharma Group</h6>
        <p>Uma empresa especializada em gestão tecnológica para o setor farmacêutico.
          Inovar e criar novas soluções voltadas para o setor farmacêutico é nossa missão.
        </p>
      </div>
      <hr class="w-100 clearfix d-md-none">
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">Produtos</h6>
        <p>
          <a href="#!">Desktop</a>
        </p>
        <p>
          <a href="#!">Mobile</a>
        </p>
      </div>
      <hr class="w-100 clearfix d-md-none">
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">ÁREA DE CLIENTES</h6>
        <p>
          <a href="#!">Industrias</a>
        </p>
        <p>
          <a href="#!">Distribuidoras</a>
        </p>
        <p>
          <a href="#!">Pdv´s</a>
        </p>
        <p>
          <a href="#!">Representantes</a>
        </p>
      </div>
      <hr class="w-100 clearfix d-md-none">
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">Contato</h6>
        <p><mdb-icon fas icon="home" class="mr-1 amber-text sombra-title"></mdb-icon>Espirito Santo, Brasil</p>
        <p><mdb-icon fas icon="envelope" class="mr-1 amber-text sombra-title"></mdb-icon>contato@tecnopharmagroup.com.br</p>
      </div>
    </div>
    <hr>
    <div class="row d-flex align-items-center text-center">
      <div class="col-md-7 col-lg-8">
        <p class="text-center text-md-center">© 2000 Copyright - Todos os Direitos Autorais Reservados à
          <a class="amber-text" href="https://tecnopharmagroup.com.br/"><strong> TecnoPharmaGroup.com.br</strong></a>
        </p>
      </div>
      <div class="col-md-5 col-lg-4 ml-lg-0">
        <div class="text-center text-md-right">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item">
              <a mdbBtn color="blue" floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab icon="facebook"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a mdbBtn color="cyan" floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab icon="twitter"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a mdbBtn color="danger" floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab icon="google-plus"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a mdbBtn color="pink" floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab icon="instagram"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a mdbBtn color="indigo" floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab icon="linkedin"></mdb-icon>
              </a>
            </li>
          </ul>
        </div>
      </div 
    </div>
  </div>
</footer -->

<footer *ngIf="menuFooter" class="page-footer center-on-small-only pt-0">
  <div class="container">
    <div class="row text-center text-md-left mt-3 pb-3">
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <h6 class="sombra-title">
          <a class="font-weight-bold green-text">Tecno</a>
          <a class="font-weight-bold amber-text">Pharma</a>
          <a class="font-weight-bold indigo-text"> Group</a>
        </h6>
        <p class="text-justify">
          Uma empresa especializada em gestão tecnológica para o setor farmacêutico.
          Inovar e criar novas soluções voltadas para o setor farmacêutico é nossa missão.
        </p>
      </div>
      <hr class="w-100 clearfix d-md-none">
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold sombra-title">Produtos</h6>
        <p><a href="#!">Desktop</a></p>
        <p><a href="#!">Mobile</a></p>
      </div>
      <hr class="w-100 clearfix d-md-none">
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold sombra-title">ÁREA DE CLIENTES</h6>
        <p><a href="#!">Industrias</a></p>
        <p><a href="#!">Distribuidoras</a></p>
        <p><a href="#!">Pdv´s</a></p>
        <p><a href="#!">Representantes</a></p>
      </div>
      <hr class="w-100 clearfix d-md-none">
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3 pl-0 pr-0">
        <h6 class="text-uppercase mb-4 font-weight-bold sombra-title">Contato</h6>
        <p><mdb-icon fas icon="home" class="mr-1 amber-text sombra-title"></mdb-icon>Espirito Santo, Brasil</p>
        <p><mdb-icon fas icon="envelope" class="mr-1 amber-text sombra-title"></mdb-icon>contato@tecnopharmagroup.com.br</p>
      </div>
    </div>    
  </div>
  <div class="footer-copyright wow fadeIn text-center py-3" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
      <div class="container-fluid pt-2">
        <p class="text-center text-md-center">© 2000 - Todos os Direitos Autorais Reservados à
          <a href="https://tecnopharmagroup.com.br/">
            <strong> 
              <a class="font-weight-bold green-text">Tecno</a>
              <a class="font-weight-bold amber-text">Pharma</a>
              <a class="font-weight-bold indigo-text">Group</a>.com.br  
            </strong>
          </a>
          <i class="brazil flag"></i>
        </p>  
      </div>
  </div>
</footer>


<footer *ngIf="!menuFooter" class="page-footer font-small cyan-skin">
  <div class="footer-copyright text-center py-3">© 2000 Todos os Direitos Autorais Reservados à
    <a href="https://tecnopharmagroup.com.br/"> 
      <strong> 
        <a class="font-weight-bold green-text">Tecno</a>
        <a class="font-weight-bold amber-text">Pharma</a>
        <a class="font-weight-bold indigo-text">Group</a>.com.br  
      </strong>
    </a>
  </div>
</footer>

import { ToastService } from 'ng-uikit-pro-standard';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {
  infoBtn: string = 'Saiba Mais...';

  constructor(
    public toast: ToastService
  ) { }

  cards = [
    {
      title: 'B2BConnect - Gestão Indústria',
      description: 'Solução para controle e gerenciamento da cadeia de Negócios da Industria, Distribuidoras e PDVs.',
      buttonText: this.infoBtn,
      buttonId: 1,      
      img: 'https://tecnopharmagroup.com.br/imgs/PharmaPed-Business.png'
    },
    {
      title: 'B2BConnect - Gestão de Força de Vendas',
      description: 'Gestão mobile de captação de Pedidos para força de vendas externa, Supervisores e Representantes.',
      buttonText: this.infoBtn,
      buttonId: 2,      
      img: 'https://tecnopharmagroup.com.br/imgs/pharmaped-ind-mobile.jpg'
    },
    {
      title: 'B2BConnect Check-In - Gestão de Entradas',
      description: 'Solução mobile para Gestão de Entradas de Mercadorias através de Check-in por código de barras.',
      buttonText: this.infoBtn,
      buttonId: 3,      
      img: 'https://tecnopharmagroup.com.br/imgs/mobile-entradas.jpg'
    },
    {
      title: 'B2BConnect Admin - Gestão de eCommerce OL',
      description: 'Versão mobile para gerenciamento administrativo do B2BConnect e seus recursos.',
      buttonText: this.infoBtn,
      buttonId: 4,      
      img: 'https://tecnopharmagroup.com.br/imgs/mobile-vendas.jpg'
    },
    {
      title: 'B2BConnect Compras - Gestão de OL',
      description: 'Controle de acordos comerciais realizados pelo Representante em seus clientes, distribuidores e PDVs.',
      buttonText: this.infoBtn,
      buttonId: 5,      
      img: 'https://tecnopharmagroup.com.br/imgs/mobile-pharmaped-ol.jpg'
    },
    {
      title: 'B2BConnect Admin - Gestão de OL',
      description: 'Administração do B2BConnect OL, cobrindo todas as funções necessárias para gestão do mesmo.',
      buttonText: this.infoBtn,
      buttonId: 6,      
      img: 'https://tecnopharmagroup.com.br/imgs/mobile-pharmaped-admin.jpg'
    },
    // {
    //   title: 'B2BConnect Admin - Gestão Farmácias',
    //   description: 'Gestão mobile para Administradores do ERP PDVs, abragendo todas as funções administrativas.',
    //   buttonText: this.infoBtn,
    //   buttonId: 7,      
    //   img: 'https://tecnopharmagroup.com.br/imgs/mobile-pdv.jpg'
    // },
    // {
    //   title: 'B2BConnect Compras - Gestão Farmácias',
    //   description: 'Solução mobile para Gestão do B2BConnect Compras do PDV, solução para compradores das Farmácias.',
    //   buttonText: this.infoBtn,
    //   buttonId: 8,      
    //   img: 'https://tecnopharmagroup.com.br/imgs/mobile-compras.jpg'
    // },
    // {
    //   title: 'B2BConnect CRM - Gestão Farmácias',
    //   description: 'Versão mobile do B2BConnect CRM, permitindo o controle de funções disponiveis na versão Web/Desktop. ',
    //   buttonText: this.infoBtn,
    //   buttonId: 9,      
    //   img: 'https://tecnopharmagroup.com.br/imgs/mobile-fidelizacao-1280x720.jpg'
    // },
    {
      title: 'B2BConnect VP - Gestão Venda Premiada',
      description: 'Controle de premiação dos parceiros comerciais. Representantes/OL e Distribuidoras.',
      buttonText: this.infoBtn,
      buttonId: 5,      
      img: 'https://clubb2b.com.br/images/main/shop_purple.png'
    },
  ];

  slides: any = [[]];

  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
  }

  onRotasMobile(RotaId: number):void {
    switch(RotaId) { 
      case 1: 
        // this.router.navigate(['/e4styles']);
        setTimeout(
          () => this.toast.warning('Estará disponivel em Breve!')
          );        
        break;
      case 2: 
        // this.router.navigate(['/e4styles']);
        setTimeout(
          () => this.toast.warning('Estará disponivel em Breve!')
          );        
        break;
      case 3: 
        // this.router.navigate(['/e4quick']);
        setTimeout(
          () => this.toast.warning('Estará disponivel em Breve!')
          );        
        break;
      case 4: 
       // this.router.navigate(['/e4blacksales']);
       setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 5: 
      //  this.router.navigate(['/e4pets']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 6: 
      //  this.router.navigate(['/farmaciasja']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 7: 
      //  this.router.navigate(['/imarmitas']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 8: 
      //  this.router.navigate(['/e4autos']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 9: 
      //  this.router.navigate(['/trustboy']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      default:
        console.log("Rota Inválida");
        break;        
    }
  }
}
<footer class="page-footer font-small pt-4">
    <div class="container text-center text-md-left">
      <div class="row d-flex align-items-center">
        <div class="col-md-7 col-lg-12">
          <p class="text-center text-md-center">© 2000 Copyright - Todos os Direitos Autorais Reservados à
            <a class="amber-text" href="https://tecnopharmagroup.com.br/"><strong> TecnoPharmaGroup.com.br</strong></a>
          </p>
        </div>
      </div>
    </div>
</footer>
<div id="about" class="bg text-center py-5 pb-5">
  <div class="container px-md-3 px-sm-0">
    
    <section class="pb-3 text-center ">
      <h2 class="h1 py-5">Modelo de eCommerce para Sua Loja</h2>
      <p class="grey-text mb-5 text-justify">
        Abaixo uma simulação do PharmaPed eCommerce para sua Loja, essa é uma demonstração resumida.
        A sua Loja PharmaPed pode ser customizada com novas fucionalidades, consulte-nos para saber mais.  
      </p>
      <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark indigo justify-content-between">
        <mdb-navbar-brand>
          <img src="https://tecnopharmagroup.com.br/imgs/PharmaPed.png" height="60" alt="">
          <a class="yellow-text sombra">Delivery</a>
        </mdb-navbar-brand>
        <links>

          <!-- Links -->
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link waves-light" mdbWavesEffect>Home<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link waves-light" mdbWavesEffect>Quem Somos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link waves-light" mdbWavesEffect>Formas de Pagamento</a>
            </li>
            
          </ul>
          <!-- Links -->
      
          <ul class="navbar-nav ml-auto nav-flex-icons">
            <li class="nav-item">
              <a class="nav-link"><mdb-icon fab icon="opencart"></mdb-icon></a>
            </li>
            <li class="nav-item">
              <a class="nav-link"><mdb-icon fas icon="motorcycle"></mdb-icon></a>
            </li>
            <li class="nav-item red-text">
              <a class="nav-link"><mdb-icon fas icon="filter"></mdb-icon></a>
            </li>
          </ul>
        </links>
      </mdb-navbar>    
      <div id="buscar" class="row pt-5 ml-3">
        <form class="form-inline waves-light" mdbWavesEffect>
          <div class="form-group row">
            <div class="col-lg-12">
              <input mdbInput type="text" class="form-control" id="Pesquisa" placeholder="Pesquise seu produto">
              <button mdbBtn color="success" outline="true" size="sm" class="my-0 waves-light" type="submit" mdbWavesEffect>Buscar</button>
            </div>
          </div>
        </form>
      </div>                
      <div mdbScrollSpyElement="scrollspy1" id="home" class="row pt-5">
        <div class="col-lg-3 col-md-6 mb-4">
          <mdb-card class="collection-card z-depth-1-half">
            <div class="view zoom waves-light" mdbWavesEffect>
              <mdb-card-img src="https://tecnopharmagroup.com.br/imgs/ofertas-cards.png" alt=""></mdb-card-img>
              <div class="stripe dark">
                <a>
                  <p>Ofertas
                    <mdb-icon fas icon="angle-right"></mdb-icon>
                  </p>
                </a>
              </div>
            </div>
          </mdb-card>
        </div>
        <div class="col-lg-3 col-md-6 mb-4">
          <mdb-card class="collection-card z-depth-1-half">
            <div class="view zoom waves-light" mdbWavesEffect>
              <mdb-card-img src="https://tecnopharmagroup.com.br/imgs/med-card.png" alt=""></mdb-card-img>
              <div class="stripe light">
                <a>
                  <p>Medicamentos
                    <mdb-icon fas icon="angle-right"></mdb-icon>
                  </p>
                </a>
              </div>
            </div>
          </mdb-card>
        </div>
        <div class="col-lg-3 col-md-6 mb-4">
          <mdb-card class="collection-card z-depth-1-half">
            <div class="view zoom waves-light" mdbWavesEffect>
              <mdb-card-img src="https://tecnopharmagroup.com.br/imgs/dermocosmeticos-card.png" alt=""></mdb-card-img>
              <div class="stripe dark">
                <a>
                  <p>Dermocosméticos
                    <mdb-icon fas icon="angle-right"></mdb-icon>
                  </p>
                </a>
              </div>
            </div>
          </mdb-card>
        </div>
        <div class="col-lg-3 col-md-6 mb-4">
          <mdb-card class="collection-card z-depth-1-half">
            <div class="view zoom waves-light" mdbWavesEffect>
              <mdb-card-img src="https://tecnopharmagroup.com.br/imgs/cosmetics-card.png" alt=""></mdb-card-img>
              <div class="stripe light">
                <a>
                  <p>Higiêne e Beleza
                    <mdb-icon fas icon="angle-right"></mdb-icon>
                  </p>
                </a>
              </div>
            </div>
          </mdb-card>
        </div>
      </div>
    </section>
  </div>
</div>
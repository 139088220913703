import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import 'jarallax';

import { AuthService } from './../../../services/auth.service';

declare var jarallax: any;

@Component({
  selector: 'app-vp-page',
  templateUrl: './vp-page.component.html',
  styleUrls: ['./vp-page.component.scss']
})
export class VpPageComponent implements OnInit, AfterViewInit {
  mostraBtnBottom = false;  

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (document.documentElement.scrollTop <= 400) {
      this.mostraBtnBottom = false;
    } else {
      this.mostraBtnBottom = true;
    }
  }

  constructor(public mostra: AuthService) { }

  ngOnInit(): void {
    // Mostra/Esconde Botão de fim de página para Scroll
    this.mostra.mostraBtnBottomEmitter.subscribe(
      mostrar => (this.mostraBtnBottom = mostrar)
    );     
  }

  ngAfterViewInit() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2
    });
  }

  scrollBME(Status: boolean):void {
    if (Status) {
      this.mostra.mostraBtnBottomEmitter.emit(true);
    } else {
      this.mostra.mostraBtnBottomEmitter.emit(false);
    }      
  }

}

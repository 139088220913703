<div class="container">

  <hr class="my-5">

  <!-- Dark form -->
  <div class="row">

    <div class="col-md-6 mx-auto">
      <!--h4 class="text-center">Dark form</h4-->
      <!--Section: Live preview-->
      <section class="form-dark">

        <!--Form without header-->
        <div class="card card-image" style="background-image: url('https://mdbootstrap.com/img/Photos/Others/pricing-table7.jpg');">
          <div class="text-white rgba-stylish-strong py-5 px-5 z-depth-4">

            <!--Header-->
            <div class="text-center">
              <h3 class="white-text mb-5 mt-4 font-weight-bold">
                <strong>SEJA BEM</strong>
                <a class="green-text font-weight-bold">
                  <strong> VINDO!</strong>
                </a>
              </h3>
            </div>

            <!--Body-->
            <form [formGroup]="darkForm"  (ngSubmit)="onSubmit()">
              <div class="md-form form-lg">
                <input type="text" ([ngModel])="usuario.email" id="email" class="form-control white-text" formControlName="email">
                <label for="email">Seu Email</label>
              </div>

              <div class="md-form form-pg pb-3">
                <input type="password" ([ngModel])="usuario.password" id="password" class="form-control white-text" formControlName="password">
                <label for="password">Sua Senha</label>
              </div>

            <!--Grid row-->
            <div class="row d-flex align-items-center mb-4">

              <!--Grid column-->
              <div class="text-center text-dark mb-3 col-md-12">
                <button type="submit" mdbBtn color="warning" block="true" rounded="true" class="z-depth-1 aqua-gradient waves-light"
                  mdbWavesEffect [disabled]="!darkForm.valid">ACESSAR<i class="fas fa-sign-in-alt fa-lg ml-2"></i></button>
              </div>
              <!--Grid column-->
            </div>
            <!--Grid row-->
            </form>

            <!--Grid column-->
            <div class="col-md-12">
              <p class="font-small white-text d-flex justify-content-end">Perdeu sua senha?
                <a class="green-text ml-1 font-weight-bold" routerLink="/"> Recuperar</a>
              </p>
            </div>
            <!--Grid column-->

          </div>
        </div>
        <!--/Form without header-->

      </section>
      <!--Section: Live preview-->
    </div>

  </div>

  <div class="row d-flex align-items-center mb-4"></div>

</div>

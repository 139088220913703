<div class="container text-center">

  <hr class="my-5">

  <section class="section pb-3 wow fadeIn text-center" data-wow-delay="0.3s">

    <h1 class="font-weight-bold text-center h1 my-3">Linha de Softwares Desktop</h1>

    <p class="font-weight-bold text-justify mb-3 mx-auto w-responsive">
      Possuímos uma variedade de Softwares voltados para a maioria das necessidades de sua empresa, 
      seja ela, Industria, Distribuidora, Farmácia ou Representante Comercial. Veja nossa linha de softwares para Desktops.
    </p>

    <mdb-carousel [isControls]="true" class="carousel-multi-item multi-animation text-center" [type]="'carousel-multi-item'"
      [animation]="'slide'">
      <mdb-carousel-item #produtos *ngFor="let item of slides; let i = index">
        <div class="col-md-4" [ngClass]="{'d-none d-md-block': cardIndex !== 0}" *ngFor="let card of item; let cardIndex = index">
          <mdb-card class="my-1">
            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
            <mdb-card-body>
              <mdb-card-title>
                <h4>{{card.title}}</h4>
              </mdb-card-title>
              <p class="text-justify">{{card.description}}</p>
              <a mdbBtn color="unique" rounded="true" size="sm" class="waves-light center" (click)="onRotasDesktop(card.buttonId)" 
                mdbWavesEffect>
                <mdb-icon fas icon="dollar-sign" class="left"></mdb-icon>
                {{card.buttonText}}
              </a>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-carousel-item>
    </mdb-carousel>        

  </section>

  <div class="row d-flex align-items-center mb-4"></div>  

</div>  

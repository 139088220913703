import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastService } from 'ng-uikit-pro-standard';
import { AuthService } from './../services/auth.service';

import { Usuario } from '../interfaces/usuario';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
  public usuario: Usuario = <Usuario>{};
  public logadoHide: boolean = true;
  darkForm: FormGroup;

  constructor(public fb: FormBuilder,
              private router: Router,
              private authService: AuthService,
              private toastr: ToastService) {
    this.darkForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      logado: ['S']
    });
  }

  onSubmit() {
    this.usuario = this.darkForm.value;
    this.authService.logar(this.usuario).subscribe(
      () => {
        this.toastr.warning('Login realizado com sucesso.', 'ATENÇÃO!');
        if (this.authService.userRole === 'ROLE_ADMIN') {
          this.router.navigate(['dashboards/admin']);
        }
        if (this.authService.userRole === 'ROLE_ADMIN_REPRE') {
          this.router.navigate(['dashboards/clientes']);
        }
        if (this.authService.userRole === 'ROLE_FARMA') {
          this.router.navigate(['dashboards/farma']);
        }
        if (this.authService.userRole === 'ROLE_IND') {
          this.router.navigate(['dashboards/industria']);
        }
        if (this.authService.userRole === 'ROLE_REPRE') {
          this.router.navigate(['dashboards/repre']);
        }
      },
      (erro) => {
        if (erro.status && erro.status === 401) {
          this.toastr.error(erro.error.reason);
        } else {
          this.toastr.error(erro.error.reason);
        }
      }
    );
  }
}

import { ToastModule, ToastService } from 'ng-uikit-pro-standard';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss']
})
export class DesktopComponent implements OnInit {

  infoBtn: string = 'Solicite um Orçamento';

  constructor(
    public toast: ToastService
  ) { }

  cards = [
    {
      title: 'B2BConnect - Gestão Indústria',
      description: 'Solução para controle e gerenciamento da cadeia de Negócios da Industria, Distribuidoras e PDVs.',
      buttonText: this.infoBtn,
      buttonId: 1,
      img: 'https://tecnopharmagroup.com.br/imgs/industria-1280x720.jpg'
      //img: '../../assets/imgs/industria-1280x720.jpg'
    },
    {
      title: 'ERP TecnoPharma - Gestão Distribuidoras',
      description: 'ERP específico para o setor de distribuição do setor farmacêutico, perfumaria e hospitalar.',
      buttonText: this.infoBtn,
      buttonId: 2,      
      img: 'https://tecnopharmagroup.com.br/imgs/distribuidora.jpg'
      //img: '../../assets/imgs/distribuidora.jpg'      
    },
    {
      title: 'B2BConnect - Gestão Ecommerce',
      description: 'Gestão de Ecommerce, solução ampla que não se resume somente a captação de pedidos.',
      buttonText: this.infoBtn,
      buttonId: 3,      
      img: 'https://tecnopharmagroup.com.br/imgs/eletronico-1280x720.png'
    },
    {
      title: 'B2BConnect -  Gestão OL/Representantes',
      description: 'Solução de Negócios para Operações de Logistica de Representantes e empresas de Representação.',
      buttonText: this.infoBtn,
      buttonId: 4,      
      img: 'https://tecnopharmagroup.com.br/imgs/repre-1280x720.png'
    },
    {
      title: 'B2BConnect - CRM OL/Representantes',
      description: 'Gerenciamento com controle de leads de compras da carteira de clientes de Distribuição e PDVs.',
      buttonText: this.infoBtn,
      buttonId: 5,      
      img: 'https://tecnopharmagroup.com.br/imgs/repre-compras-1280x720.jpg'
    },
    {
      title: 'B2BConnect - eCommerce OL/Representante',
      description: 'Solução de Ecommerce, com funções que vão além da captação de pedidos nos PDVs.',
      buttonText: this.infoBtn,
      buttonId: 6,      
      img: 'https://tecnopharmagroup.com.br/imgs/repre-ecommerce-1280x720.png'
    },
    // {
    //   title: 'e4Pharma - Gestão de Gerenciamento de Farmácias',
    //   description: 'ERP para gestão de Farmácias e Drogarias, com funções e controles inovadores do setor.',
    //   buttonText: this.infoBtn,
    //   buttonId: 7,      
    //   img: 'https://tecnopharmagroup.com.br/imgs/farmacias-pdv-1280x720.jpg'
    // },
    // {
    //   title: 'e4Pharma - Gestão de Compras para Farmácias',
    //   description: 'Frente de Loja integrado ao ERP para controle de vendas, emissão de NFCe e demais funcionalidades.',
    //   buttonText: this.infoBtn,
    //   buttonId: 8,      
    //   img: 'https://tecnopharmagroup.com.br/imgs/farma-pdv-compras-1280x720.jpg'
    // },
    // {
    //   title: 'e4Pharma - Gestão de CRM para Farmácias',
    //   description: 'Solução inovadora de Fidelização de clientes usando Inteligência Artificial, integrado com o ERP.',
    //   buttonText: this.infoBtn,
    //   buttonId: 9,      
    //   img: 'https://tecnopharmagroup.com.br/imgs/farma-fideliza-1280x720.jpg'
    // },
  ];
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
  }

  onRotasDesktop(RotaId: number):void {
    switch(RotaId) { 
      case 1: 
        // this.router.navigate(['/e4styles']);
        setTimeout(
          () => this.toast.warning('Estará disponivel em Breve!')
          );        
        break;
      case 2: 
        // this.router.navigate(['/e4styles']);
        setTimeout(
          () => this.toast.warning('Estará disponivel em Breve!')
          );        
        break;
      case 3: 
        // this.router.navigate(['/e4quick']);
        setTimeout(
          () => this.toast.warning('Estará disponivel em Breve!')
          );        
        break;
      case 4: 
       // this.router.navigate(['/e4blacksales']);
       setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 5: 
      //  this.router.navigate(['/e4pets']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 6: 
      //  this.router.navigate(['/farmaciasja']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 7: 
      //  this.router.navigate(['/imarmitas']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 8: 
      //  this.router.navigate(['/e4autos']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      case 9: 
      //  this.router.navigate(['/trustboy']);
      setTimeout(
        () => this.toast.warning('Estará disponivel em Breve!')
        );        
        break;
      default:
        console.log("Rota Inválida");
        break;        
    }
  }

}

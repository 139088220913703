import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from './core/core.module';

import { MainLayoutComponent } from './main/layout/main-layout/main-layout.component';
import { HomePageComponent } from './main/pages/home-page/home-page.component';
import { BlogComponent } from './blog/blog.component';
import { DesktopComponent } from './desktop/desktop.component';
import { DesktopLayoutComponent } from './desktop/layout/desktop-layout.component';
import { MobileComponent } from './mobile/mobile.component';
import { MobileLayoutComponent } from './mobile/layout/mobile-layout.component';
import { VpLayoutComponent } from './vendasPremiada/layout/vp-layout/vp-layout.component';
import { VpPageComponent } from './vendasPremiada/pages/vp-page/vp-page.component';
import { PharmapedLayoutComponent } from './pharmaped/layout/pharmaped-layout.component';
import { PharmapedDistComponent } from './pharmaped/pages/pharmaped-dist/pharmaped-dist.component';
import { PharmapedFarmaComponent } from './pharmaped/pages/pharmaped-farma/pharmaped-farma.component';

export const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        component: HomePageComponent
      }
    ]
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'desktop',
    component: DesktopLayoutComponent,
    children: [
      {
        path: "",
        component: DesktopComponent
      }
    ]    
  },
  {
    path: 'mobile',
    component: MobileLayoutComponent,
    children: [
      {
        path: "",
        component: MobileComponent
      }
    ]
  },
  {
    path: "vendasPremiada",
    component: VpLayoutComponent,
    children: [
      {
        path: "",
        component: VpPageComponent
      },
    ]
  },
  {
    path: "pharmapedDist",
    component: PharmapedLayoutComponent,
    children: [
      {
        path: "",
        component: PharmapedDistComponent
      },
    ]
  },
  {
    path: "pharmapedFarma",
    component: PharmapedLayoutComponent,
    children: [
      {
        path: "",
        component: PharmapedFarmaComponent
      },
    ]
  },  
  {
    path: '**',
    redirectTo: '/'
  },
]

@NgModule({
  imports: [
    CoreModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [ RouterModule]
})

export class AppRoutingModule { }

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';

import { CoreModule } from './core/core.module';
import { BlogComponent } from './blog/blog.component';
import { DesktopComponent } from './desktop/desktop.component';
import { LoginComponent } from './login/login.component';
import { MainFooterComponent } from './main/components/main-footer/main-footer.component';
import { MainLayoutComponent } from './main/layout/main-layout/main-layout.component';
import { MainMenuComponent } from './main/components/main-menu/main-menu.component';
import { MobileComponent } from './mobile/mobile.component';
import { MobileLayoutComponent } from './mobile/layout/mobile-layout.component';
import { HomePageComponent } from './main/pages/home-page/home-page.component';
import { VpMenuComponent } from './vendasPremiada/components/vp-menu/vp-menu.component';
import { VpFooterComponent } from './vendasPremiada/components/vp-footer/vp-footer.component';
import { VpLayoutComponent } from './vendasPremiada/layout/vp-layout/vp-layout.component';
import { VpPageComponent } from './vendasPremiada/pages/vp-page/vp-page.component';
import { environment } from 'src/environments/environment';
import { MenuSharedComponent } from './shared/components/menu-shared/menu-shared.component';
import { FooterSharedComponent } from './shared/components/footer-shared/footer-shared.component';
import { DesktopLayoutComponent } from './desktop/layout/desktop-layout.component';
import { PharmapedLayoutComponent } from './pharmaped/layout/pharmaped-layout.component';
import { PharmapedDistComponent } from './pharmaped/pages/pharmaped-dist/pharmaped-dist.component';
import { PharmapedFarmaComponent } from './pharmaped/pages/pharmaped-farma/pharmaped-farma.component';

@NgModule({
  declarations: [
    AppComponent,
    BlogComponent,
    DesktopComponent,
    DesktopLayoutComponent,
    HomePageComponent,
    LoginComponent,
    MainMenuComponent,    
    MainFooterComponent,    
    MainLayoutComponent,
    MobileComponent,
    MobileLayoutComponent,
    VpMenuComponent,
    VpFooterComponent,
    VpLayoutComponent,
    VpPageComponent,
    MenuSharedComponent,
    FooterSharedComponent,
    PharmapedLayoutComponent,
    PharmapedDistComponent,
    PharmapedFarmaComponent,
  ],
  imports: [
    CoreModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    /*AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    }), */
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
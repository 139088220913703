import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-menu-shared',
  templateUrl: './menu-shared.component.html',
  styleUrls: ['./menu-shared.component.scss']
})
export class MenuSharedComponent implements OnInit {
  @Input() menuColorEmitter: boolean;

  constructor(
    public router: Router,
    public mostra: AuthService) { }

  ngOnInit(): void {
    this.mostra.menuColorEmitter.emit(true);
  }

  voltaHome(): void {
    //this.mostra.menuColorEmitter.emit(true);
    this.mostra.menuMainEmitter.emit(true);
    this.mostra.menuSharedEmitter.emit(false);
    this.router.navigate(['/']);
  }

}

import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vp-menu',
  templateUrl: './vp-menu.component.html',
  styleUrls: ['./vp-menu.component.scss']
})
export class VpMenuComponent implements OnInit {

  constructor(
    public router: Router,
    public mostra: AuthService) { }

  ngOnInit(): void {}

  voltaHome(): void {
    this.mostra.menuMainEmitter.emit(true);
    this.mostra.menuVPEmitter.emit(false);
    this.router.navigate(['/']);
  }

}

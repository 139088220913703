<div class="container">

  <hr class="my-5">

  <section class="section pb-3 wow fadeIn text-center" data-wow-delay="0.3s">

    <!--Section heading-->
    <h1 class="font-weight-bold text-center h1 my-2">Linha de Softwares Mobile</h1>

    <!--Section description-->
    <p class="font-weight-bold text-justify mb-2 mx-auto w-responsive">
      Possuímos uma variedade de Softwares voltados para a maioria das necessidades 
      de sua empresa, seja ela, Industria, Distribuidora, Farmácia,
      Representante Comercial. Veja nossa linha de softwares para Mobile.
    </p>

    <mdb-carousel [isControls]="true" class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'"
      [animation]="'slide'">
      <mdb-carousel-item *ngFor="let item of slides; let i = index">
        <div class="col-md-4" [ngClass]="{'d-none d-md-block': cardIndex !== 0}" *ngFor="let card of item; let cardIndex = index">
          <mdb-card class="my-1">
            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
            <mdb-card-body>
              <mdb-card-title>
                <h4>{{card.title}}</h4>
              </mdb-card-title>
              <p class="text-justify">{{card.description}}</p>
              <a mdbBtn color="unique" size="sm" rounded="true" class="waves-light" (click)="onRotasMobile(card.buttonId)"               
                mdbWavesEffect>
                <mdb-icon fas icon="info-circle" class="left"></mdb-icon>                
                {{card.buttonText}}
              </a>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-carousel-item>
    </mdb-carousel>        
    <!--Carousel Wrapper-->

  </section>

  <div class="row d-flex align-items-center mb-4"></div>  

</div>  

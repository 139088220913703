<div class="container">
<!--Section: Blog v.4-->
<section class="my-5">

  <!--Grid row-->
  <div class="row">
    <div class="col-md-12">
      <!--Featured image-->
      <mdb-card cascade="true" wider="true" reverse="true">
        <div class="view view-cascade overlay waves-light" mdbWavesEffect>
          <mdb-card-img src="https://mdbootstrap.com/img/Photos/Slides/img%20(142).jpg" alt="Wide sample post image"></mdb-card-img>
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

        <!--Post data-->
        <mdb-card-body cascade="true" class="text-center">
          <h2>
            <a class="font-weight-bold">Brasil participará de reunião do ICH sobre fármacos</a>
          </h2>
          <p>Fonte:
            <a>Anvisa</a>, 10/05/2019 16:39
          </p>

          <!--Social shares-->
          <div class="social-counters ">

            <!--Facebook-->
            <a mdbBtn class="btn-fb waves-light" mdbWavesEffect>
              <i class="fa fa-facebook left "></i>
              <span class="clearfix d-none d-md-inline-block">Facebook</span>
            </a>
            <span class="counter ">46</span>

            <!--Twitter-->
            <a mdbBtn class="btn-tw waves-light" mdbWavesEffect>
              <i class="fa fa-twitter left "></i>
              <span class="clearfix d-none d-md-inline-block">Twitter</span>
            </a>
            <span class="counter ">22</span>

            <!--Google+-->
            <a mdbBtn class="btn-gplus waves-light" mdbWavesEffect>
              <i class="fa fa-google-plus left "></i>
              <span class="clearfix d-none d-md-inline-block">Google+</span>
            </a>
            <span class="counter ">31</span>

            <!--Comments-->
            <a mdbBtn color="default" class="waves-light" mdbWavesEffect>
              <i class="fa fa-comments-o left "></i>
              <span class="clearfix d-none d-md-inline-block">Comentários</span>
            </a>
            <span class="counter ">18</span>

          </div>
          <!--Social shares-->
        </mdb-card-body>
        <!--Post data-->
      </mdb-card>

      <!--Excerpt-->
      <div class="excerpt mt-5">
        <p class="text-justify">
          A Anvisa participará, entre 1º e 6 de junho de 2019, da próxima reunião presencial do Conselho Internacional de Harmonização de Fármacos para Uso Humano (International Council for Harmonisation of Technical Requirements for Pharmaceuticals for Human Use – ICH), em Amsterdã, na Holanda.
        </p>
        <p class="text-justify">
          O encontro presencial engloba diversas atividades, que ocorrerão paralelamente. Ao todo, serão 16 grupos de trabalho...
        </p>
      </div>
    </div>
  </div>
  <!--Grid row-->

  <hr class="mb-5 mt-4">

  <!--Grid row-->
  <div class="row">
    <div class="col-md-12">
      <!--Featured image-->
      <mdb-card cascade="true" wider="true" reverse="true">
        <div class="view view-cascade overlay waves-light" mdbWavesEffect>
          <img src="http://portal.anvisa.gov.br/image/journal/article?img_id=5495614&t=1557858815334" alt="Dicol"  class="img-fluid" width="1110" height="289">
          <!--img src="https://mdbootstrap.com/img/Photos/Slides/img%20(146).jpg" alt="Second wide sample post image" class="img-fluid"-->
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

        <!--Post data-->
        <mdb-card-body cascade="true" class="text-center">
          <h2>
            <a class="font-weight-bold">Acompanhe a reunião pública da Dicol nesta terça</a>
          </h2>
          <p>Fonte:
            <a>Anvisa</a>, 13/05/2019 18:09</p>

          <!--Social shares-->
          <div class="social-counters ">

            <!--Facebook-->
            <a mdbBtn class="btn-fb waves-light" mdbWavesEffect>
              <i class="fa fa-facebook left "></i>
              <span class="clearfix d-none d-md-inline-block">Facebook</span>
            </a>
            <span class="counter ">23</span>

            <!--Twitter-->
            <a mdbBtn class="btn-tw waves-light" mdbWavesEffect>
              <i class="fa fa-twitter left "></i>
              <span class="clearfix d-none d-md-inline-block">Twitter</span>
            </a>
            <span class="counter ">47</span>

            <!--Dribbble-->
            <a mdbBtn class="btn-gplus waves-light" mdbWavesEffect>
              <i class="fa fa-google-plus left "></i>
              <span class="clearfix d-none d-md-inline-block">Google+</span>
            </a>
            <span class="counter ">19</span>

            <!--Comments-->
            <a mdbBtn color="default" class="waves-light" mdbWavesEffect>
              <i class="fa fa-comments-o left "></i>
              <span class="clearfix d-none d-md-inline-block">Comentários</span>
            </a>
            <span class="counter ">34</span>

          </div>
          <!--Social shares-->
        </mdb-card-body>

        <!--Post data-->
      </mdb-card>

      <!--Excerpt-->
      <div class="excerpt mt-5">
        <p class="text-justify">
          A partir das 10h desta terça-feira (14/5), acontece a 12ª Reunião Ordinária Pública (ROP) da Diretoria Colegiada (Dicol) da Anvisa, na sala de reuniões da Agência, com transmissão em tempo real. Veja abaixo a trasmissão:
        </p>

        <p class="text-justify">Na pauta, entre outros temas, estão duas propostas de Resolução da Diretoria Colegiada (RDC): uma que trata de aditivos alimentares contendo alumínio e outra sobre a reavaliação toxicológica do ingrediente ativo ácido 2,4-diclorofenoxiacético (2,4-D).
          A Diretoria avaliará também propostas de consulta pública sobre o prazo de validade da regularização de produtos de higiene pessoal, perfumes e cosméticos e sobre o prazo de validade da regularização de produtos saneantes.
        </p>

        <p>
          Confira a <a href="http://portal.anvisa.gov.br/documents/33760/5252417/Pauta+da+12%C2%AA+Reuni%C3%A3o+Ordin%C3%A1ria+P%C3%BAblica+da+Dicol+de+2019+-+Republicada/0f168840-ccb4-431c-93d8-22b4c4ebef88">pauta da reunião</a>.

          Assista a reunião em tempo real: <a href="https://youtu.be/4xhLTYr_v6o">https://youtu.be/4xhLTYr_v6o.</a>
        </p>
      </div>
    </div>
  </div>
  <!--Grid row-->

  <hr class="mb-5 mt-4">

</section>
<!--Section: Blog v.4-->
</div>

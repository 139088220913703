import { Injectable } from '@angular/core';
import { PHARMA_TOKEN } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  get token() {
   return localStorage.getItem(PHARMA_TOKEN);
  }

  set token(token: string) {
    localStorage.setItem(PHARMA_TOKEN, token);
  }

  resetToken() {
    localStorage.removeItem(PHARMA_TOKEN);
  }
}

<mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar intro-fixed-nav" [containerInside]="true">
    <mdb-navbar-brand>
        <a class="navbar-brand" href="#">
            <img src="https://tecnopharmagroup.com.br/imgs/logo.png" height="35" width="90" alt="">
        </a>
    </mdb-navbar-brand>
    <links>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a (click)="voltaHome()" class="nav-link waves-light" mdbWavesEffect mdbPageScroll
                    [pageScrollDuration]="750">
                    <i class="fa fa-home amber-text"> </i>
                    Início<span class="sr-only">(current)</span>
                </a>
            </li>
            <li class="nav-item active">
                <a class="nav-link waves-light" mdbWavesEffect href="#pctBasico" mdbPageScroll
                    [pageScrollDuration]="750">
                    <i class="fas fa-handshake amber-text"> </i>    
                    Contratar
                </a>
            </li>
        </ul>
    </links>
</mdb-navbar>
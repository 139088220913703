import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {
  menuFooter: boolean = true;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.menuFooterEmitter.subscribe(
      mostrar => this.menuFooter = mostrar
    );
  }

}
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccordionModule, ButtonsModule, CarouselModule, 
         IconsModule, MDBSpinningPreloader, ModalModule, NavbarModule, 
         PopoverModule, ScrollSpyModule, SidenavModule, SmoothscrollModule, 
         TooltipModule, WavesModule } from 'ng-uikit-pro-standard';

import { AuthService } from '../services/auth.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    AccordionModule,
    ButtonsModule,     
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule, 
    FormsModule,
    HttpClientModule,
    IconsModule,
    ModalModule,    
    NavbarModule,
    PopoverModule,    
    TooltipModule, 
    ReactiveFormsModule,
    ScrollSpyModule,    
    SmoothscrollModule,
    SidenavModule,
    WavesModule
  ],
  providers: [MDBSpinningPreloader, AuthService],  
})
export class CoreModule { }

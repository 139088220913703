<div class="view intro jarallax jarallax-img"
     style="background-image: url(https://clubb2b.com.br/images/main/shop_purple.png); background-attachment: fixed; height: 800px;">
    <div id="topo">
        <div class="full-bg-img mask rgba-white-light">
            <div class="container">
                <div class="d-flex align-items-center d-flex justify-content-center mt-0" style="height: 1300px">
                    <div class="row mb-5 pb-5">
                        <div class="col-md-12 wow fadeIn mb-5 pb-5">
                            <div class="intro-info-content text-center pt-5 mb-5 pb-5">
                                <h2 class="h1 py-0 font-weight-bold text-center deep-orange-text sombra-title">
                                    <a class="green-text">Vendas Premiada</a>
                                    <p>Sua Ferramenta de Gestão de Premiação</p>
                                </h2>

                                <p class="font-weight-bold px-0 mb-4 pb-2 lead black-text text-justify sombra">
                                    O <a class="font-weight-bold green-text sombra-title">Vendas Premiada</a>
                                    é um conjunto de ferramentas para gerenciamento de vendas de produtos efetuadas por seus parceiros.
                                    Uma 
                                    <a class="font-weight-bold yellow-text sombra-title">Dashboard</a> 
                                    para gerenciamento dos seus produtos e criação de 
                                    <a class="font-weight-bold yellow-text sombra-title">Campanhas de Premiação</a>, e um 
                                    <a class="font-weight-bold yellow-text sombra-title">App</a> 
                                    para que seus parceiros possam acompanhar a evolução das vendas nas 
                                    <a class="font-weight-bold yellow-text sombra-title">Faixas de Metas de Premiações</a> 
                                    estipuladas por você.
                                </p>

                                <div class="row mt-0 pt-0 pb-5 mb-5">
                                    <div class="col-md-4 pb-5 mb-5 center-on-small-only flex-center">
                                        <img src="https://clubb2b.com.br/images/main/iphone.png" height="500" width="250" alt="" class="z-depth-0">
                                    </div>

                                    <div class="col-md-8">
                                        <div class="row mb-2">
                                            <div class="col-2">
                                                <mdb-icon fas icon="chart-line" size="2x" class="red-text sombra-title"></mdb-icon>
                                            </div>
                                            <div class="col-10 text-justify">
                                                <h5 class="font-weight-bold sombra-title">
                                                    <a class="font-weight-bold green-text">Dashboard</a> 
                                                </h5>
                                                <p class="black-text sombra-title">
                                                    Crie campanhas de Vendas pela nossa moderna e intuitiva 
                                                    <a class="font-weight-bold yellow-text">Dashboard</a> Web,
                                                    controle as vendas de seus produtos com seus parceiros comerciais,
                                                    acompanhe o progresso das vendas por parceiro, produtos ou ambos. 
                                                    Categorize suas campanhas dando a elas faixas de 
                                                    <a class="font-weight-bold yellow-text">Premiação como Metas</a>,
                                                    para que seus parceiros possam colaborar no aumento das vendas de seus produtos.
                                                    Importe as vendas através do XML da NFe gerada por seus parceiros de vendas.
                                                    E muito mais...
                                                </p>
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <div class="col-2">
                                                <mdb-icon fas icon="mobile" size="2x" class="indigo-text sombra-title"></mdb-icon>
                                            </div>
                                            <div class="col-10 text-left">
                                                <h5 class="font-weight-bold sombra-title">
                                                    <a class="font-weight-bold green-text">e</a>
                                                    <a class="font-weight-bold yellow-text">4</a>
                                                    <a class="font-weight-bold green-text">VP</a>
                                                </h5>
                                                <p class="black-text text-justify sombra-title">
                                                    O 
                                                    <a class="font-weight-bold green-text sombra-title">e</a>
                                                    <a class="font-weight-bold yellow-text sombra-title">4</a>
                                                    <a class="font-weight-bold green-text sombra-title">VP</a>,
                                                    foi elaborado para facilitar o acompanhamento da Vendas e da Premiação,
                                                    bem como permitir sua interação com seus parceiros de Vendas.
                                                    Com diversas funções modernas como Mensagens de Alerta, Chat em tempo real,                                                
                                                    e muito mais...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-floating btn-large deep-orange waves-light" (click)="scrollBME(false)" 
        [hidden]="!mostraBtnBottom" mdbPageScroll href="#topo" mdbWavesEffect
        style="right:35px; bottom:35px;" [pageScrollDuration]="750">
        <i class="fa fa-arrow-up"></i>
    </button>    

    <div id="pctBasico" class="container">
    <div class="row">
        <div class="col-md-12 text-center pt-2 mb-2">
        <h2 class="h1 font-bold light-blue-text my-4">PLANOS DE ADESÃO</h2>
        <p class="text-justify">
            Escolha o Plano que mais se adequa a suas necessidades. Nossos planos possuem caratér de descontos
            progressivos por tempo de uso, ou seja, quanto maior o tempo de uso, maior o desconto no seu plano.
            Veja abaixo nosso planos e descontos.  
        </p>
        </div>
    </div>
    </div>

    <div class="view intro jarallax jarallax-img" 
        style="background-image: url(https://clubb2b.com.br/images/main/businessman.jpg); background-attachment: fixed; height: 800px;">
        <div class="full-bg-img mask rgba-white-light">
            <div class="container flex-center">
                <div class="row mt-4 pt-5 mb-4 pb-4 py-2">
                    <div class="col-md-12 wow fadeIn" style="animation-name: none; visibility: visible;">
                        <div class="text-center">
                            <h2 class="h1 font-weight-bold text-center deep-orange-text sobra-title">                            
                                Planos de Pagamentos
                            </h2>
                            <p class="white-text font-weight-bold text-justify pb-4 sombra-title">
                                Os Planos de Pagamentos dividem-se em três opções: Trimestral, Semestral e Anual.
                                Todos os Planos são baseados nos padrões de contratação online, você contrata o plano e começa 
                                a usar até o final do periodo contratado, podendo ser renovado a qualquer momento pelo usuário.
                            </p>

                            <div class="row">
                                <div class="col-lg-3 col-md-12 mb-4">
                                    <mdb-card>
                                        <mdb-card-body>
                                            <mdb-card-title>
                                                <h5 class="mb-4 font-weight-bold">Plano Mensal</h5>
                                            </mdb-card-title>
                                            <div class="card-circle mx-auto d-flex flex-center">
                                                <mdb-icon far icon="grin" size="3x" class="light-blue-text"></mdb-icon>
                                            </div>
                                            <h2 class="font-weight-bold my-3">R$ 360</h2>
                                            <p class="dark-text text-justify">
                                                Pacote com pagamento para <a class="font-weight-bold text-dark">acesso mensal</a>,
                                                necessário sua renovação a cada 30 dias.
                                                Acesso ao conjunto de aplicativos web e mobile para gerenciamento das Vendas
                                                Premiada dos seus parceiros.
                                                <a class="font-weight-bold text-dark">Custo mensal de R$ 360,00 </a> 
                                                sem limite de parceiros.
                                            </p>
                                            <a mdbBtn color="green" outline="true" rounded="true" class="waves-light"
                                                mdbWavesEffect>Comprar</a>
                                        </mdb-card-body>
                                    </mdb-card>
                                </div>                                
                                <div class="col-lg-3 col-md-12 mb-4">
                                    <mdb-card>
                                        <mdb-card-body>
                                            <mdb-card-title>
                                                <h5 class="mb-4 font-weight-bold">Plano Trimestral</h5>
                                            </mdb-card-title>
                                            <div class="card-circle mx-auto d-flex flex-center">
                                                <mdb-icon far icon="grin-stars" size="3x" class="light-blue-text"></mdb-icon>
                                            </div>
                                            <h2 class="font-weight-bold my-3">R$ 980</h2>
                                            <p class="dark-text text-justify">
                                                Nesse pacote você paga pelo <a class="font-weight-bold text-dark">acesso de 3 meses de uso</a>,
                                                tendo acesso a um conjunto de aplicativos Web e Mobile para gerenciamento das Vendas
                                                Premiada dos seus parceiros.
                                                <a class="font-weight-bold text-dark">Você ganha um desconto de 10.2%, </a> 
                                                obetendo uma economia de R$ 100,00.
                                            </p>
                                            <a mdbBtn color="green" outline="true" rounded="true" class="waves-light"
                                                mdbWavesEffect>Comprar</a>
                                        </mdb-card-body>
                                    </mdb-card>
                                </div>

                                <div class="col-lg-3 col-md-12 mb-4">
                                    <mdb-card bgColor="purple-gradient">
                                        <mdb-card-body>
                                            <mdb-card-title>
                                                <h5 class="mb-4 font-weight-bold">Plano Semestral</h5>
                                            </mdb-card-title>
                                            <div class="card-circle mx-auto d-flex flex-center">
                                                <mdb-icon far icon="grin-hearts" size="3x" class="purple-text"></mdb-icon>
                                            </div>
                                            <h2 class="font-weight-bold my-3">R$ 1.620</h2>
                                            <p class="dark-text text-justify">
                                                Pacote 
                                                <a class="font-weight-bold text-dark">Semestral, assinatura de 6 meses de acesso</a>, 
                                                com acesso ao conjunto de aplicativos Web e Mobile para gerenciamento das 
                                                Vendas Premiada dos seus parceiros.
                                                Neste modelo de plano o 
                                                <a class="font-weight-bold text-dark"> 
                                                    desconto é de 25%, economia de R$ 540,00 no Semestre.
                                                </a>
                                            </p>
                                            <a mdbBtn color="green" outline="true" rounded="true" class="waves-light"
                                                mdbWavesEffect>Comprar</a>
                                        </mdb-card-body>
                                    </mdb-card>
                                </div>

                                <div class="col-lg-3 col-md-12 mb-4">
                                    <mdb-card>
                                        <mdb-card-body>
                                            <mdb-card-title>
                                                <h5 class="mb-4 font-weight-bold">Plano Anual</h5>
                                            </mdb-card-title>
                                            <div class="card-circle mx-auto d-flex flex-center">
                                                <mdb-icon fas icon="heart" size="3x" class="red-text"></mdb-icon>
                                            </div>
                                            <h2 class="font-weight-bold my-3">R$ 3.024 </h2>
                                            <p class="dark-text text-justify">
                                                No pacote
                                                <a class="font-weight-bold text-dark"> 
                                                    Anual você paga 12 meses e ganha 30% de desconto, uma economia de R$ 1.296 reais. 
                                                </a> 
                                                Com acesso ao conjunto de aplicativos Web e Mobile para gerenciamento das 
                                                Vendas Premiada dos seus parceiros.
                                            </p>
                                            <br>
                                            <a mdbBtn color="green" outline="true" rounded="true" class="waves-light"
                                                mdbWavesEffect>Comprar</a>
                                        </mdb-card-body>
                                    </mdb-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>